'use client'

import { useAnalyticsImpressions } from 'analytics/contexts/AnalyticsImpressionsProvider'
import type { Item } from 'analytics/utils/getEECItems/getEECItems'
import { Slideshow } from 'fukku/Slideshow'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useAnimationImagesLoad } from 'product-card/hooks/useAnimationImagesLoad'
import { useProductCardAnimationContext } from 'product-card/hooks/useProductCardAnimationContext'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardHoverContext } from 'product-card/hooks/useProductCardHoverContext'
import { useProductCardImagesSizesContext } from 'product-card/hooks/useProductCardImagesSizesContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { ProductCardAnimation, ProductCardVariant } from 'product-card/types'
import { getSortedImages } from 'product-card/utils/getSortedImages'
import type { Product, ProductImageType } from 'product/types'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'
import { env } from 'utils/envManager'
import { generateImagesSizes } from 'utils/image/generateImagesSizes/generateImagesSizes'

import { PdpImageLinkWrapper } from './PdpImageLinkWrapper/PdpImageLinkWrapper'
import SmartCropImage from './SmartCropImage/SmartCropImage'

import styles from './ProductImage.module.scss'
import fukkuStyles from 'fukku/styles/classes.module.scss'

export type ImageDisplayType = 'single' | 'slider'

interface ProductImageProps {
	product: Product
	imageSortCriteria: ProductImageType[]
	variant: ProductCardVariant
	priority?: boolean
	showAsFeatured?: boolean
}

const IMPRESSION_THRESHOLD = 0.5

const FIRST_IMAGE = 0
const SECOND_IMAGE = 1

export const ProductImage = ({
	product,
	imageSortCriteria,
	variant,
	priority,
	showAsFeatured,
}: ProductImageProps) => {
	const { isTouchable } = useResponsive()

	const {
		lookId,
		selectedColorId,
		dataTestId,
		productId,
		productListId,
		draggable,
	} = useProductCardContext()

	const { animation } = useProductCardAnimationContext()

	const {
		shouldRenderSlider,
		shouldRenderFeatured,
		layoutView,
		hasDotsIndicator,
	} = useProductCardConsumerLayoutContext()
	const { imagesSizes } = useProductCardImagesSizesContext()
	const { selectedColor } = useProductColors({ product })
	const { addImpression } = useAnalyticsImpressions()
	const productImageRef = useRef<HTMLImageElement | null>(null)
	const entry = useIntersectionObserver(productImageRef, {
		threshold: IMPRESSION_THRESHOLD,
		freezeOnceVisible: true,
	})

	const { isHovered } = useProductCardHoverContext()

	const sortedImages = useMemo(
		() =>
			getSortedImages({
				product,
				imageSortCriteria,
				showAsFeatured,
				lookId,
				shouldRenderFeatured,
				colorId: selectedColorId,
			}),
		[
			product,
			imageSortCriteria,
			showAsFeatured,
			shouldRenderFeatured,
			selectedColorId,
			lookId,
		]
	)

	const { isFirstImageReady, areImagesReadyForAnimation, handleImageLoaded } =
		useAnimationImagesLoad(sortedImages)

	const imageDisplayType = useMemo<ImageDisplayType>(() => {
		if (sortedImages.length === 1 || !shouldRenderSlider) {
			return 'single'
		}
		return 'slider'
	}, [shouldRenderSlider, sortedImages.length, variant])

	const currentImages =
		isHovered || !isTouchable ? sortedImages : sortedImages.slice(0, 1)

	const sizes = useMemo(
		() =>
			generateImagesSizes(
				showAsFeatured ? imagesSizes.featured : imagesSizes.regular
			),
		[imagesSizes]
	)

	useEffect(() => {
		if (entry?.isIntersecting) {
			const item: Item = {
				colorId: selectedColorId,
				listId: productListId,
				quantity: 1,
				lookId,
				productId,
				layoutView,
			}
			addImpression(item)
		}
	}, [entry?.isIntersecting, productId])

	const metaImage = (
		<meta
			itemProp='image'
			content={new URL(sortedImages[0].img, env.NEXT_PUBLIC_SITE_BASE_URL).href}
		/>
	)

	const getImagePriority = useCallback(
		(index: number) => {
			return (
				(priority && index === FIRST_IMAGE) ||
				(animation === ProductCardAnimation.Slideshow &&
					[FIRST_IMAGE, SECOND_IMAGE].includes(index))
			)
		},
		[animation, priority]
	)

	// Apply fade in animation if there is any animation defined
	const fadeInAnimation = isFirstImageReady ? styles.fadeIn : styles.invisible

	if (imageDisplayType === 'single') {
		return (
			<div
				className={cx(
					styles.productImage,
					animation !== ProductCardAnimation.None && fadeInAnimation
				)}
				data-testid='productCard.image'
			>
				<div
					className={styles.imageWrapper}
					data-testid='productCard.image.wrapper'
				>
					<PdpImageLinkWrapper
						product={product}
						imageDisplayType={imageDisplayType}
					>
						{metaImage}
						<SmartCropImage
							data-testid={dataTestIds.productCardImage(dataTestId, 0)}
							alt={sortedImages[0].alt}
							src={sortedImages[0].img}
							sizes={sizes}
							priority={priority}
							ref={productImageRef}
							showAsFeatured={showAsFeatured}
							draggable={draggable}
							fill
							onLoad={() => handleImageLoaded(sortedImages[0])}
						/>
						<span className={fukkuStyles.srOnly}>
							{product.name} {selectedColor?.label} ({product.reference})
						</span>
					</PdpImageLinkWrapper>
				</div>
			</div>
		)
	}

	if (imageDisplayType === 'slider') {
		return (
			<div
				className={cx(styles.productImage, fadeInAnimation)}
				data-testid='productCard.images'
				ref={productImageRef}
			>
				<PdpImageLinkWrapper
					product={product}
					imageDisplayType={imageDisplayType}
				>
					{metaImage}
					<Slideshow
						className={styles.slideShow}
						isTouchable={isTouchable}
						dataTestId={dataTestIds.productCardSlideshow(dataTestId)}
						hasPreload
						hasInitialAnimation={
							animation === ProductCardAnimation.Slideshow &&
							areImagesReadyForAnimation
						}
						hasDotsIndicator={hasDotsIndicator}
					>
						{currentImages?.map((image, index) => (
							<div key={image.alt} className={styles.imageWrapper}>
								<SmartCropImage
									data-testid={dataTestIds.productCardImage(dataTestId, index)}
									alt={image.alt}
									src={image.img}
									sizes={sizes}
									priority={getImagePriority(index)}
									showAsFeatured={showAsFeatured}
									fill
									draggable={draggable}
									onLoad={() => handleImageLoaded(image)}
								/>
							</div>
						))}
					</Slideshow>
				</PdpImageLinkWrapper>
			</div>
		)
	}

	return null
}
