'use client'

import { PRODUCT_STOCK_CLIENT_SERVICE_URL } from 'product/constants/constants'
import type { Stock } from 'product/types/Stock'
import { multipleUrlsFetcher } from 'services/rest/multipleUrlsFetcher'
import useSWRImmutable from 'swr/immutable'
import { env } from 'utils/envManager'

interface UseProductsStockType {
	productIds: string[]
	country: string
}

export const useProductsStock = ({
	productIds,
	country,
}: UseProductsStockType) => {
	const searchParams = new URLSearchParams({
		countryIso: country,
		channelId: env.NEXT_PUBLIC_CHANNEL,
	})

	const urls = productIds.map((productId) => {
		const url = new URL(
			`${PRODUCT_STOCK_CLIENT_SERVICE_URL}${productId}`,
			env.NEXT_PUBLIC_SITE_BASE_URL
		)
		url.search = searchParams.toString()

		return url.href
	})

	const { data, isLoading, mutate } = useSWRImmutable(
		urls,
		multipleUrlsFetcher<Stock>
	)

	return {
		data:
			data &&
			Object.values(data).reduce(
				(acc: Record<string, Stock>, value: Stock, index: number) => {
					if (!value) {
						return acc
					}
					return {
						...acc,
						[productIds[index]]: value,
					}
				},
				{}
			),
		isLoading,
		mutate,
	}
}
