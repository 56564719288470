import { useProductCardAnimationContext } from 'product-card/hooks/useProductCardAnimationContext'
import { ProductCardAnimation } from 'product-card/types'
import type { ProductImage } from 'product/types'
import { useCallback, useMemo, useState } from 'react'
import { some } from 'utils/some/some'

/* eslint react-hooks/exhaustive-deps: warn */

/**
 * This hook manages product card image load state so we know when the images are ready for the animation to be displayed.
 *
 * @param {ProductImage[]} sortedImages - The product card images to be loaded.
 *
 * @returns {Object} An object that exposes:
 *   - `handleImageLoaded` - a function accepting an image (of type `ProductImage`) and updating the internal state of loaded images.
 *   - `isFirstImageReady` - a property indicating that the first image has finished loading.
 *   - `areImagesReadyForAnimation` - a property indicating that the images needed to render the animation have finished loading.
 */
export const useAnimationImagesLoad = (sortedImages: ProductImage[]) => {
	const { animation } = useProductCardAnimationContext()

	const [loadedImages, setLoadedImages] = useState(
		new Set<ProductImage['img']>()
	)

	const handleImageLoaded = useCallback(
		(image: ProductImage) =>
			setLoadedImages(
				(currentImages) => new Set([...currentImages, image.img])
			),
		[]
	)

	const imageHasLoaded = useCallback(
		(image: ProductImage | undefined) => image && loadedImages.has(image.img),
		[loadedImages]
	)

	const isFirstImageReady = useMemo(() => {
		const [firstImage] = sortedImages
		return imageHasLoaded(firstImage)
	}, [imageHasLoaded, sortedImages])

	const areImagesReadyForAnimation = useMemo(() => {
		const [firstImage, secondImage] = sortedImages

		const canDisplayFadeIn =
			animation === ProductCardAnimation.FadeIn && imageHasLoaded(firstImage)

		const canDisplaySlideshow =
			animation === ProductCardAnimation.Slideshow &&
			imageHasLoaded(firstImage) &&
			imageHasLoaded(secondImage)

		return some(canDisplayFadeIn, canDisplaySlideshow)
	}, [animation, imageHasLoaded, sortedImages])

	return useMemo(
		() => ({
			handleImageLoaded,
			isFirstImageReady,
			areImagesReadyForAnimation,
		}),
		[isFirstImageReady, areImagesReadyForAnimation, handleImageLoaded]
	)
}
