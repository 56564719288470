'use client'

import { PdpClientLink } from 'links/pdp/client'
import type { PdpLinkProps } from 'links/pdp/types'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { getProductLinkLabel } from 'product-card/utils/getProductLinkLabel/getProductLinkLabel'
import type { Product } from 'product/types'

import type { ImageDisplayType } from '../ProductImage'

import styles from '../ProductImage.module.scss'

type PdpImageLinkWrapperProps = React.PropsWithChildren<{
	product: Product
	imageDisplayType: ImageDisplayType
}>

export const PdpImageLinkWrapper = ({
	product,
	children,
	imageDisplayType,
}: PdpImageLinkWrapperProps) => {
	const { lookId, selectedColorId, productListId, draggable } =
		useProductCardContext()

	const { disableImageLink } = useProductCardConsumerLayoutContext()

	const linkProps = {
		...(imageDisplayType === 'single' ? { className: styles.link } : {}),
		linkProps: {
			'aria-label': getProductLinkLabel(product, selectedColorId),
			draggable,
		},
	}

	const pdpClientLinkProps: Omit<PdpLinkProps, 'children'> = {
		product,
		lookId,
		linkProps,
		colorId: selectedColorId,
		listId: productListId,
		metaAs: imageDisplayType === 'slider' ? 'url' : undefined,
	}

	return Boolean(disableImageLink) ? (
		children
	) : (
		<PdpClientLink {...pdpClientLinkProps}>{children}</PdpClientLink>
	)
}
