import type { ImagesSizes } from '../types'

export const generateImagesSizes = (imagesSizes: ImagesSizes[]): string =>
	imagesSizes
		.map(({ maxWidth, minWidth, size }) => {
			if (maxWidth) {
				return `(max-width: ${maxWidth}) ${size}`
			}
			if (minWidth) {
				return `(min-width: ${minWidth}) ${size}`
			}
			return size
		})
		.join(', ')
