'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { ProductCardHoverProvider } from 'product-card/context/ProductCardHoverContext'
import { ProductCardRefProvider } from 'product-card/context/ProductCardRefContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { ProductCardVariant } from 'product-card/types'
import type { Product, ProductColor, ProductImageType } from 'product/types'
import type { ProductPrices } from 'product/types/Prices'
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useMemo,
	useRef,
} from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import { useProductCardConsumerLayoutContext } from '../../hooks/useProductCardConsumerLayoutContext'
import { useProductColors } from '../../hooks/useProductColors'
import { ProductCardActionButtons } from './ProductCardActionButtons'
import { ProductDetails } from './ProductDetails'
import { ProductImageContainer } from './ProductImageContainer'

import styles from './ProductCard.module.scss'

interface ProductCardBaseProps {
	product: Product | undefined
	imageSortCriteria: ProductImageType[]
	prices?: ProductPrices // This property allows us to manage the call for product pricing from the client or server as needed.
	isProductLoading?: boolean
	priority?: boolean
	isTwoColumnSize?: boolean
	className?: string
	setShouldFetch?: Dispatch<SetStateAction<boolean>>
	showDiscount?: boolean
}

function getShowAsFeatured(
	shouldRenderFeatured: boolean,
	isLargeOrGreater: boolean,
	isTwoColumnSize = false
) {
	return !!isTwoColumnSize && shouldRenderFeatured && isLargeOrGreater
}

function isReadyToRender(
	haveProductLooks: boolean,
	isIntersecting = false,
	priority = false
) {
	return haveProductLooks && (isIntersecting || priority)
}

function getHaveProductLooks(selectedColor: ProductColor | undefined) {
	return !!selectedColor?.looks && Object.keys(selectedColor.looks).length > 0
}

export const ProductCard = ({
	product,
	imageSortCriteria,
	priority,
	prices,
	isTwoColumnSize,
	isProductLoading = false,
	className,
	setShouldFetch,
	showDiscount,
}: ProductCardBaseProps) => {
	const { isQa } = useMasterData()
	const { isLargeOrGreater } = useResponsive()
	const productCardRef = useRef<HTMLDivElement | null>(null)
	const { showActionIcon, variant, shouldRenderFeatured } =
		useProductCardConsumerLayoutContext()
	const { productId, dataTestId, setIcon } = useProductCardContext()

	useEffect(() => {
		setIcon(showActionIcon)
	}, [showActionIcon, setIcon])

	const productCardEntry = useIntersectionObserver(productCardRef, {
		threshold: [0, 1],
		rootMargin: `0px 0px 50% 0px`,
		freezeOnceVisible: true,
	})

	/**
	 * Get Product Image Data.
	 */
	const { selectedColor } = useProductColors({ product })
	const haveProductLooks = getHaveProductLooks(selectedColor)

	/**
	 * This product has been marked to span two columns, this means that is a featured product on large screen devices.
	 */
	const showAsFeatured = getShowAsFeatured(
		shouldRenderFeatured,
		isLargeOrGreater,
		isTwoColumnSize
	)

	useEffect(() => {
		if (productCardEntry?.isIntersecting && setShouldFetch) {
			setShouldFetch(true)
		}
	}, [productCardEntry?.isIntersecting])

	const readyToShowCard = isReadyToRender(
		haveProductLooks,
		productCardEntry?.isIntersecting,
		priority
	)

	// [QA request]  When products that have only one size,
	// disclaim: not are products that only have one size available by stock.
	const isSingleSize = useMemo(() => {
		if (!isQa || !selectedColor?.sizes) {
			return undefined
		}
		// return undefined on multiple sizes to avoid rendering the data-single-size attribute
		return selectedColor.sizes.length === 1
	}, [isQa, selectedColor?.sizes])

	const emptyClass = showAsFeatured ? styles.emptyFeatured : styles.empty

	return (
		<div
			className={cx(
				styles.productCard,
				className,
				isProductLoading && emptyClass
			)}
			data-testid={dataTestId}
			data-single-size={isSingleSize}
			ref={productCardRef}
		>
			{product && readyToShowCard ? (
				<ProductCardRefProvider initialProductCardRef={productCardRef}>
					<div
						data-testid={
							showAsFeatured
								? dataTestIds.productCardFeatured(dataTestId)
								: undefined
						}
					>
						<ProductCardHoverProvider>
							<ProductImageContainer
								productId={productId}
								product={product}
								imageSortCriteria={imageSortCriteria}
								priority={priority}
								showAsFeatured={showAsFeatured}
							/>
						</ProductCardHoverProvider>
						{variant === ProductCardVariant.General && (
							<ProductDetails
								productId={productId}
								product={product}
								prices={prices}
								showAsFeatured={showAsFeatured}
								showDiscount={showDiscount}
							/>
						)}
					</div>
					{variant === ProductCardVariant.General && (
						<ProductCardActionButtons product={product} />
					)}
				</ProductCardRefProvider>
			) : (
				<div className={emptyClass} />
			)}
		</div>
	)
}
