'use client'

import {
	type ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import type { ImagesSizes } from 'utils/image/types'

export interface ImagesSizesLayout {
	regular: ImagesSizes[]
	featured?: ImagesSizes[]
}

export interface ImagesSizesLayoutContext {
	regular: ImagesSizes[]
	featured: ImagesSizes[]
}

interface ImageSizesContextProps {
	imagesSizes: ImagesSizesLayoutContext
	setImagesSizes: (imagesSizes: ImagesSizesLayout) => void
}

interface ImageSizesProviderProps {
	defaultImageSizes: ImagesSizesLayout
	children: ReactNode
}

export const ProductCardImagesSizesContext =
	createContext<ImageSizesContextProps | null>(null)

export const ProductCardImagesSizesProvider = ({
	children,
	defaultImageSizes,
}: ImageSizesProviderProps) => {
	const [imagesSizes, setImagesSizes] = useState<{
		regular: ImagesSizes[]
		featured?: ImagesSizes[]
	}>(defaultImageSizes)

	useEffect(() => {
		setImagesSizes(defaultImageSizes)
	}, [defaultImageSizes])

	const values = useMemo(
		() => ({
			imagesSizes: {
				regular: imagesSizes.regular,
				featured: imagesSizes.featured ?? imagesSizes.regular,
			},
			setImagesSizes,
		}),
		[imagesSizes, setImagesSizes]
	)
	return (
		<ProductCardImagesSizesContext.Provider value={values}>
			{children}
		</ProductCardImagesSizesContext.Provider>
	)
}
