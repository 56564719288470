import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { type RefAttributes, forwardRef } from 'react'
import { akamaiSmartCropLoaderFactory } from 'utils/image/loaders/akamaiSmartCropLoaderFactory'

const ASPECT_RATIO_WIDTH = 5
const ASPECT_RATIO_HEIGHT = 7
const ASPECT_RATIO = ASPECT_RATIO_WIDTH / ASPECT_RATIO_HEIGHT

const ASPECT_RATIO_A2_WIDTH = 7
const ASPECT_RATIO_A2_HEIGHT = 4.89
const ASPECT_RATIO_A2 = ASPECT_RATIO_A2_WIDTH / ASPECT_RATIO_A2_HEIGHT

interface SmartCropImageProps
	extends ImageProps,
		RefAttributes<HTMLImageElement> {
	showAsFeatured?: boolean
}

const SmartCropImage = (
	{
		src,
		sizes,
		priority,
		alt,
		showAsFeatured,
		draggable = true,
		onLoad,
	}: SmartCropImageProps,
	ref: React.Ref<HTMLImageElement>
) => {
	const productImageHeightCalc = (width: number) => {
		if (showAsFeatured) {
			return width / ASPECT_RATIO_A2
		}

		return width / ASPECT_RATIO
	}

	const akamaiSmartCropLoader = akamaiSmartCropLoaderFactory({
		heightCalcFunction: productImageHeightCalc,
	})

	return (
		<Image
			alt={alt}
			src={src}
			sizes={sizes}
			priority={priority}
			ref={ref}
			fill
			loader={akamaiSmartCropLoader}
			draggable={draggable}
			onLoad={onLoad}
		/>
	)
}

export default forwardRef(SmartCropImage)
